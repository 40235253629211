<template>
  <el-form :model="addressForm" ref="areasForm" class="area-form" size="small" :rules="rules">
      <el-row>
          <el-col :span="defaultSpan" v-if="areaLevel==-1||areaLevel>=6">
            <el-form-item label="省份" :label-width="formLabelWidth" prop="provinceId">
              <el-select class="fix-width" filterable v-model="addressForm.provinceId" value-key="id" @change="changeProvince(addressForm.provinceId)" placeholder="省份">
                <el-option label="省" value=""></el-option>
                <el-option v-for="province in provinceList" :key="province.id" :label="province.name" :value="province.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="defaultSpan" style="padding-left:8px" v-if="areaLevel==-1||areaLevel>=5">
            <el-form-item label="城市" :label-width="formLabelWidth" prop="cityId">
            <el-select class="fix-width" filterable v-model="addressForm.cityId" value-key="id" @change="changeCity(addressForm.cityId)" placeholder="城市">
              <el-option label="市" value=""></el-option>
              <el-option v-for="city in showCityList" :key="city.id" :label="city.name" :value="city.id"></el-option>
            </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="defaultSpan" style="padding-left:8px" v-if="(areaLevel==-1||areaLevel>=4)">
            <el-form-item label="区域" :label-width="formLabelWidth" prop="areaId">
            <el-select  class="fix-width" filterable v-model="addressForm.areaId" value-key="id" @change="changeArea(addressForm.areaId)" placeholder="区县">
              <el-option label="区" value=""></el-option>
              <el-option v-for="area in showAreaList" :key="area.id" :label="area.name" :value="area.id"></el-option>
            </el-select>
            </el-form-item>
          </el-col>
      </el-row>
  </el-form>
</template>

<script>
  import AreaService from '@/services/AreaService'
  import GroupService from '@/services/GroupService'
  import ConfigUtil from '@/common/Config'
  import AreaList from '@/common/AreaList'
  export default {
    props: {
      addressModel: {
        type: Object,
        default:function(){
          return {};
        }
      },
      areaId:String,
      type: {
        type: String,
        default: "address" //area village
      },
      areaLevel:{
        type: Number,
        default: -1 //area village
      },
    },
    data() {
      return {
        provinceList: [], //省份列表
        showCityList: [], //城市列表
        showAreaList: [], //区域列表
      
        addressForm: {
          provinceId: '',
          provinceName:'',
          cityId: '',
          cityName:'',
          areaId: '',
          areaName:'',
        },
        defaultSpan:8,
        lastSpan:0,
        formLabelWidth: '90px',
        rules:{
          provinceId: [
            { required: true, message: '请选择省份', trigger: 'change' }
          ],
          cityId: [
            { required: true, message: '请选择城市', trigger: 'change' }
          ],
          areaId: [
            { required: true, message: '请选择区域', trigger: 'change' }
          ],
        }
        
      }
    },
    watch: {
      areaId:function (newValue) {
        if(newValue){
          this.setValue();
          this.$forceUpdate();
        }
      },
     
    },
    created(){

    },
    mounted() {
      //console.log("mounted:"+this.areaId);
      if(this.type == "area") {
        this.defaultSpan = 8;
        this.lastSpan = 8;
      } else if(this.type == "address"){
        this.defaultSpan = 5;
        this.lastSpan = 4;
      } else if(this.type == "saddress") {
        this.defaultSpan = 4;
        this.lastSpan = 12;
      } else if(this.type == "paddress") {
        this.defaultSpan = 4;
        this.lastSpan = 6;
      }

      this.init();
      this.setValue();
    },
    methods: {
      async init() {
        this.doGetArea();
      },
      async setValue() {
        if(this.areaId && this.areaId!='undefined' && this.areaId!='null') {
          console.log("setValue:",this.areaId);

          for(var i=0; i< this.provinceList.length; i++) {
            for(var j=0; j<this.provinceList[i].nodes.length; j++) {
              for(var k=0; k<this.provinceList[i].nodes[j].nodes.length; k++) {
                if(this.areaId== this.provinceList[i].nodes[j].nodes[k].id) {
                  this.showCityList = this.provinceList[i].nodes;
                  this.showAreaList = this.provinceList[i].nodes[j].nodes;
                  this.addressForm.provinceId = this.provinceList[i].id;
                  this.addressForm.provinceName = this.provinceList[i].name;
                  this.addressForm.cityId = this.provinceList[i].nodes[j].id;
                  this.addressForm.cityName = this.provinceList[i].nodes[j].name;
                  this.addressForm.areaId = this.provinceList[i].nodes[j].nodes[k].id;
                  this.addressForm.areaName = this.provinceList[i].nodes[j].nodes[k].name;
                  break;
                }
              }
            }
          }
        }

        //if(this.villageId) {
        this.changeArea(+this.areaId);
        //}
      },
      //获取省市区
      doGetArea() {
        this.provinceList = AreaList.getAreaJson();

        // console.log('省份',this.provinceList);
      },
      //修改省份
      changeProvince(provinceId) {
        var curProvince = null;
        this.provinceList.forEach(function (province) {
          if(provinceId == province.id) {
            curProvince = province;
          }
        })
        if(curProvince) {
          this.showCityList = curProvince.nodes;
        } else {
          this.showCityList = [];
        }
        this.showAreaList = [];

        this.addressForm = {
          provinceId: provinceId,
          provinceName:curProvince.name,
          cityId: '',
          cityName:'',
          areaId: '',
          areaName:'',
        }

        this.exportArea();
      },
      //修改城市
      changeCity(cityId) {
        
        var curCity = null;
        this.showCityList.forEach(function (city) {
          if(cityId == city.id) {
            curCity = city;
          }
        })

        if(curCity) {
          this.showAreaList = curCity.nodes;
        } else {
          this.showAreaList = [];
        }

        


        setTimeout(()=>{
          this.addressForm = {
            provinceId: this.addressForm.provinceId,
            provinceName: this.addressForm.provinceName,
            cityId: cityId,
            cityName:curCity.name,
            areaId: '',
            areaName:'',
          }
        }, 100)

        this.exportArea();

      },
      async changeArea(areaId) {
        
        var curArea = null;
        this.showAreaList.forEach(function (area) {
          if(areaId == area.id) {
            curArea = area;
          }
        })

        if(areaId){
          this.addressForm = {
            provinceId: this.addressForm.provinceId,
            provinceName: this.addressForm.provinceName,
            cityId: this.addressForm.cityId,
            cityName: this.addressForm.cityName,
            areaId: areaId,
            areaName: curArea.name,
          }
        }
        this.exportArea();
      },

      exportArea(){
        this.$emit('exportArea',this.addressForm)
      }
     
    }
  }
</script>

<style scoped>
</style>
