<template>
    <el-dialog 
    width="900px"
    :visible.sync="addAddressVisible"
    :close-on-click-modal="false"
    @close="close"
    class="add-address-diglog">
        <div slot="title" class="dialog-title">
            添加地址
        </div>
        <div class="add-address-content">
            <area-cascade :areaLevel="-1" :type="'area'" @exportArea="exportArea"></area-cascade>

            <el-form :model="deliverAddress" ref="addressForm" class="address-form" size="small" :rules="rules">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名" :label-width="formLabelWidth" prop="receiveName">
                        <el-input v-model="deliverAddress.receiveName" autocomplete="off" placeholder="请输入姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                    <el-form-item label="手机号码" :label-width="formLabelWidth" prop="mobile">
                        <el-input v-model="deliverAddress.mobile" autocomplete="off" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="收货地址" :label-width="formLabelWidth" prop="detailAddress">
                    <el-input v-model="deliverAddress.detailAddress" autocomplete="off" placeholder="请输入收货地址"></el-input>
                    </el-form-item>
                </el-row>
                <!-- <el-row>
                    <el-form-item label="是否默认" :label-width="formLabelWidth">
                        <el-switch v-model="deliverAddress.isDefault"></el-switch>
                    </el-form-item>
                </el-row>
             -->
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <div>
                <el-button type="primary" @click="doAddAddress" :loading="subLoading">确认添加</el-button>
            </div>
        </div>

    </el-dialog>
</template>


<script>
    import areaCascade from '@/components/AreaSelect/areaCascade.vue'
    import AddressService from '@/services/AddressService'
	import uni from '@/services/UNIService.js'


    export default{
        components:{
            areaCascade
        },
        data(){
            return {
                addAddressVisible:false,
                formLabelWidth: '90px',
				rules: {
					receiveName: [
						{ required: true, message: '请输入姓名', trigger: 'blur' },
					],
					mobile: [
						{ required: true, message: '请输入手机号', trigger: 'blur' }
					],
					detailAddress: [
						{ required: true, message: '请输入具体收货地址', trigger: 'blur' }
					],
				},
                deliverAddress: {
					areaId: '',
					city: "",
					cityId: '',
					detailAddress: "",
					district: "",
					districtId: "",
					isDefault: false,
					mobile: "",
					province: "",
					provinceId: '',
					receiveName: "",
					simpleAddress: "",
					type: 1,
					token: ""
				},
                subLoading:false,
            }
        },
        methods:{
            open(){
                this.addAddressVisible=true;
            },
            close(){
                this.addAddressVisible=false;
                this.deliverAddress={
					areaId: '',
					city: "",
					cityId: '',
					detailAddress: "",
					district: "",
					districtId: "",
					isDefault: false,
					mobile: "",
					province: "",
					provinceId: '',
					receiveName: "",
					simpleAddress: "",
					type: 1,
					token: ""
				}
                this.subLoading=false;

                this.$emit('updateAddress');
            },

            exportArea(obj){
				// console.log("order addressForm",obj);
		
				this.deliverAddress={
					areaId: obj.areaId,
					city:  obj.cityName,
					cityId:  obj.cityId,
					detailAddress:  this.deliverAddress.detailAddress,
					district:  obj.areaName,
					districtId:  obj.areaId,
					isDefault: this.deliverAddress.isDefault,
					mobile:  this.deliverAddress.mobile,
					province: obj.provinceName,
					provinceId: obj.provinceId,
					receiveName:this.deliverAddress.receiveName,
					simpleAddress: this.deliverAddress.simpleAddress,
					type: this.deliverAddress.type,
					token:uni.getStorageSync('token')
				}
			},

            // 添加地址
            async doAddAddress(){

                this.subLoading=true;

                // 表单验证不通过
                this.$refs['addressForm'].validate((valid) => {
                    if (!valid) {
                        this.$message.error('必填项不能为空');
                        this.subLoading=false;
                        return false;
                    } 
                });

                let res= await AddressService.deliver_address_add(this.deliverAddress);
            
                if(res.status==1){
                    this.close();
                    this.$message.success('添加成功');
                }else{
                    this.$message.error('添加地址失败');
                }
                this.subLoading=false;
            }
        }
    }
</script>

<style lang="scss" scoped>
.add-address-diglog{
    
    /deep/.el-dialog{
        border-radius: 8px;
        overflow:hidden ;
    }

    /deep/.el-dialog__header{
      padding:0 0 0;
    }
    /deep/.el-dialog__body{
      padding:0 0 0;
    }
    .dialog-title{
      font-size:20px;
      color: #0f71ea;
      text-align: left;
      border-bottom:2px solid #e5e5e5;
      height: 60px;
      line-height: 60px;
      padding:0 20px;
    }

    .add-address-content{
        padding:20px;

        /deep/.el-form-item{
            .el-form-item__content{
                text-align: left;
            }
        }
        
    }

    .dialog-footer{
        .el-button{
            padding:0 20px;
            width:120px;
            height:34px;
            line-height:32px;
        }
        
    }
}
</style>

