<template>
    <div class="my-address-box">
        <div class="title clearfix" >
            <el-button type="text" class="add-btn right" @click="addAddress">添加收货地址</el-button>
            <div class="tit-txt">地址管理</div>
            
        </div>
        <div class="my-address-content">

			<div class="address-list" v-if="addressItemsList.length>0">
				<div class="address-item" v-for="(item,index) in addressItemsList" :key="index">
					<div class="name-info">
						<span class="name">{{item.receiveName}}</span><span class="mobile">{{item.mobile}}</span>
					</div>
					<div class="address clearfix">
						<div class="address-txt left">
							<el-tag
							v-if="item.isDefault"
							size="mini"
								effect="dark">
								默认
							</el-tag> {{item.simpleAddress | removeNull}}
						</div>
						<i class="el-icon-edit right"  @click="editAddress(item)"></i>
					</div>
					<div class="address-bottom clearfix">
						<el-radio v-model="item.isDefault" :label="true" class=" address-default-radio left" @change="changeDefault(item)">默认地址</el-radio>
						<el-button type="text" class="right del-address" @click="delAddress(item)">删除</el-button>
					</div>
				</div>
			</div>
            
			<su-empty v-else :txt="'暂无地址~~'"></su-empty>
        </div>

		<add-address ref="addAddress" @updateAddress="updateAddress"></add-address>
		<edit-address ref="editAddress" @updateAddress="updateAddress"></edit-address>

    </div>
    
</template>
<script>
    import suCouponItem from '@/components/su-coupon-item/su-coupon-item.vue' 
    import suEmpty from '@/components/others/su-empty.vue' 
    import addAddress from '@/components/myAddress/addAddress.vue' 
    import editAddress from '@/components/myAddress/editAddress.vue' 
    

    import ConfigUtil from '@/common/Config'
	import TimeUtil from '@/common/TimeUtil'
	import AddressService from '@/services/AddressService'
	import uni from '@/services/UNIService.js'

    export default{
        components:{
            suCouponItem,
            suEmpty,
			addAddress,
			editAddress
        },

		filters: {
			removeNull: function (value) {
				if (value == null) {
					value = "";
				}
				// 如果原字符串中包含空格则替换
				return value.replaceAll("null", "");
			},
		},
        data() {
			return {
                
				currentPage: 1,
				count: 15,
				addressItemsList: [],
				loadingStatus: "",
				currentUser: {
					id: uni.getStorageSync("userId")
				},
				couponItem: null,
				toAppId: 'wxa9e33a4f1da53239',
			}

		},
        created(){
            this.readyToLoad();
        },

        methods:{
           
			async readyToLoad() {
				this.currentPage = 1;
				this.addressItemsList = []
				// uni.pageScrollTo({
				// 	scrollTop: 0 //滚动到顶部位置
				// });
				this.doPageAddressItems();
			},
			async doPageAddressItems() {
				var addressItemInfo = await AddressService.deliver_address_list();
				this.loadingStatus = "loading";
				this.addressItemsList = addressItemInfo;


				// console.log(addressItemInfo)
				// if (couponItemInfo.status != 1 || !couponItemInfo.data || couponItemInfo.data.length <= 0) {
				// 	this.loadingStatus = "noMore";
				// 	return;
				// }
				// if (couponItemInfo.morePageCount <= 0) {
				// 	this.loadingStatus = "noMore";
				// } else {
				// 	this.loadingStatus = "more";
				// }
				// var tempAddressList = [];
				// for (var i = 0; i < couponItemInfo.data.length; i++) {
				// 	var tempCoupon = CouponService.doGetCouponItemModel(couponItemInfo.data[i]);
				// 	tempCoupon.coverSummary = "有效期：" + tempCoupon.startTime + "-" + tempCoupon.endTime;
				// 	tempCoupon.isPlatform = (ConfigUtil.getBusinessTradingAreaId() == tempCoupon.tradingAreaId) ? true : false; //判断是否是该小程序平台对应的券
				// 	tempAddressList.push(tempCoupon);
				// }
				// this.addressItemsList = this.addressItemsList.concat(tempAddressList);
				// console.log(this.addressItemsList)
			},
			// 弹框关闭时更新数据
			updateAddress(){
				this.readyToLoad();
			},
			// 默认地址设置
			async changeDefault(item){
				// console.log('默认地址设置',item)
				let res=await AddressService.deliver_address_doSetDefault(uni.getStorageSync('token'),item.id);
				if(res.status==1){
					this.$message.success('默认地址设置成功');
				}else{
					this.$message.error('默认地址设置失败');
				}
				this.readyToLoad();
			},
			// 添加
			async addAddress(){
				this.$refs.addAddress.open();
			},
			//编辑
			async editAddress(item){
				// console.log('编辑数据',item)
				item.token=uni.getStorageSync('token');
				this.$refs.editAddress.open(item);
			},
			//删除地址
			delAddress(address){
				this.$confirm('确定删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let res=await AddressService.deliver_address_doDel(uni.getStorageSync('token'),address.id);

					// console.log('删除结果',res);

					if(res.status==1){
						this.readyToLoad();
						this.$message.success('删除成功');
					}else{
						this.$message.error('地址删除失败');
					}
					// console.log(res)
				}).catch(() => {
					        
				});
			}
        }
    }
</script>
<style lang="scss" scoped>
    .my-address-box{
        width:100%;
        min-height:600px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;


        .title{
            
            height:42px;
            line-height: 42px;
            border-bottom:2px solid #E4E7ED;
            text-align: left;
            padding:0 20px;

            .add-btn{
                
            }
        }

		.my-address-content{
			.address-list{
				padding:20px 20px 30px;
				.address-item{
					background: #f5f5f5;
					margin: 15px 0;
					padding:15px 20px;
					border-radius: 10px;
					overflow: hidden;

					.name-info{
						height: 50px;
						line-height: 50px;
						text-align:left;

						.name{
							font-size: 20px;
							margin-right: 20px;
							font-weight: 600;
						}
						.mobile{
							font-size: 18px;
							color: #666;
							font-weight: 600;
						}
					}

					.address{
						height: 40px;
						text-align:left;
						border-bottom:2px solid #e1e1e1;
						.address-txt{
							font-size: 18px;
							color: #333;
						}

						.el-icon-edit {
							font-size: 18px;
							cursor: pointer;
						}
					}

					.address-bottom{
						margin-top:5px;
						height:40px;
						line-height: 40px;

						.address-default-radio{
							margin-top:10px;
							font-size: 16px;
							/deep/.el-radio__inner{
								width: 20px;
								height: 20px;
							}
							/deep/.el-radio__label{
								font-size: 16px;
							}
						}

						.del-address{
							font-size: 16px;
						}
					}
				}
			}
		}
    }
</style>