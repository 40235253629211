<template>
	<div class="cu-couponlist">
		<div class="cu-dialog">
			<div class="coupon-list">
                <div class="list-item" v-for="(item,index) in coupon" :key='index'>
                    <div class="list" >
                        <div class="list-data" :class="{'coupon-lose':item.status!=4}">
                            <div class="coupon-price">
                                <div class="discounts">
                                    <span class="min">￥</span>
                                    <span class="max">{{item.reducePrice/10000}}万</span>
                                </div>
                                <div class="full-reduction">
                                    部分商品可用
                                </div>
                                <div class="jag"></div>
                            </div>
                            <!-- <view class="use-status">
                                <text >{{item.status==4?'已领取':item.status==1?'已使用':'已过期'}}</text>
                            </view> -->
                            <div class="coupon-info">
                                <div class="info-title">
                                    <div class="tag">
                                        限商品券
                                    </div>
                                    <div class="title">
                                        {{item.name}}
                                    </div>
                                </div>
                                <div class="date-get">
                                    <div class="date">
                                        <div v-if="item.endData!=9999999999999">{{item.startTime}}-{{item.endTime}}</div>
                                        <div v-else>永久有效</div>
                                    </div>
                                    <div class="get" v-if="item.status==4" @click="toNext()">
                                        <div class="use" >可用商品</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
				
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			coupon: Array
		},
		data() {
			return {

			}
		},
		methods: {
			toNext(){
				// uni.navigateTo({
				// 	url:'../../pages/CouponGoodsList/CouponGoodsList'
				// })
				this.$router.push({
					name:'mall',
					params:{
						type:3
					}
				});
        
			}
		},
	}
</script>

<style lang="scss" scoped>
$base:#1754BF;

.page-total {

	width: 100%;
	height: 100%;
	// background: rgba(0,0,0,0.3);
	overflow-x: hidden;
	overflow-y: auto;
}
/* 优惠券 */
.cu-dialog{
	width: 100%;
	height: 70%;
	border-radius: 30px 30px 0 0 !important;
	overflow: hidden;
	.modal-title{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 80px;
		text{
			font-size: 26px;
			color: #212121;
		}
	}
	.tips{
		display: flex;
		align-items: center;
		padding: 0 4%;
		height: 60px;
		color: #212121;
		font-size: 24px;
	}
	.coupon-list{
		margin: 10px auto 30px;

        .list-item{
            padding:20px 4%;
            border-bottom:2px solid #E4E7ED;
        }
        .list-item:last-child{
            border-bottom: none;
        }
		.list {
			position: relative;
			width: 100%;
            height:165px;
			box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
			border-radius: 6px;
			overflow: hidden;
            
			.list-data {
				display: flex;
				align-items: center;
				width: 100%;
				height:165px;
				.coupon-price {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 30%;
					height: 100%;
					background-color: $base;
					.discounts {
						display: flex;
						justify-content: center;
						align-items: flex-end;
						width: 100%;
						.min {
							color: #ffffff;
							font-size: 24px;
							font-weight: bold;
						}
						.max {
							font-size: 40px;
							color: #ffffff;
							font-weight: bold;
						}
					}
					.full-reduction {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 40px;
				
							font-size: 18px;
							color: #ffffff;
						
					}
					.jag {
						position: absolute;
						top: 0;
						left: -6px;
						width: 6px;
						height: 100%;
						background-size: 10px 6px;
						background-color: #fff;
						background-position: 100% 35%;
						background-image: linear-gradient(-45deg, $base 25%, transparent 25%, transparent), linear-gradient(-135deg, $base 25%, transparent 25%, transparent),
							linear-gradient(-45deg, transparent 75%, $base 75%), linear-gradient(-135deg, transparent 75%, $base 75%);
					}
				}
				.coupon-info {
					width: 70%;
					height: 90%;
					padding: 0 4%;
					.info-title {
						display: flex;
						width: 100%;
						height: 38px;
                        line-height: 38px;
						.tag {
							margin-right: 10px;
								padding: 0 16px;
								color: #ffffff;
								background-color: $base;
								font-size: 18px;
								border-radius: 20px;
							
						}
						.title {
							max-width: 220px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow:ellipsis;
						
								font-size: 22px;
								color: #212121;
							
						}
					}
					.date-get {
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						height: 80px;
						border-bottom: 2px dashed #eeeeee;
						.date {
							display: flex;
							align-items: center;
							
								font-size: 20px;
								color: #555555;
							
						}
						.get {
							
								padding: 0;
								background-color: #fff;
								color: #ffffff;
								font-size: 18px;
								border-radius: 100px;
                                cursor: pointer;
							
							.use {
                                height: 38px;
                                line-height: 32px;
                                padding:0 20px;
                                border-radius: 38px;
								background-color: transparent;
								border: 2px solid $base;
								color: $base;
							}
						}
					}
					.describe-title {
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						height: 40px;
					
							color: #555555;
							font-size: 24px;
						
						.more {
							transform: rotate(90deg);
						}
					}
				}
			}
			.coupon-lose{
				.coupon-price{
					background-color: #c0c0c0;
					.jag {
						position: absolute;
						top: 0;
						left: -6px;
						width: 6px;
						height: 100%;
						background-size: 10px 6px;
						background-color: #fff;
						background-position: 100% 35%;
						background-image: linear-gradient(-45deg, #c0c0c0 25%, transparent 25%, transparent), linear-gradient(-135deg, #c0c0c0 25%, transparent 25%, transparent),
							linear-gradient(-45deg, transparent 75%, #c0c0c0 75%), linear-gradient(-135deg, transparent 75%, #c0c0c0 75%);
					}
				}
				.coupon-info{
					.info-title{
						.tag{
							
								background-color: #c0c0c0;
							
						}
						.title{
						
								color: #c0c0c0;
							
						}
					}
					.date-get{
						.date{
						
								color: #c0c0c0;
							
						}
					}
				}
			}
			.describe{
				display: flex;
				align-items: center;
				padding: 0 4%;
				height: 60px;
				background-color: #FFFFFF;
				box-shadow: 0 -10px 10px #F5F5F5;
		
					color: #CCCCCC;
					font-size: 24px;
				
			}
			.use-status {
				position: absolute;
				right: -20px;
				top: -25px;
				z-index: 10;
				width: 100px;
				height: 100px;
				border: 2px solid #c0c0c0;
				border-radius: 100%;
			
					display: inline-block;
					color: #c0c0c0;
					font-size: 24px;
					transform: rotate(45deg);
					margin-top: 40px;
				
			}
		}
	}
}
		
</style>